body,
.card,
.card-header,
.hero {
    background-color: var(--skin-background-color-1);
    font-family: var(--skin-body-font);
    color: var(--skin-main-text-color-1);
}

.hero {
    border-bottom-color: var(--skin-background-color-1);
}

.main-menu {
    background-color: var(--skin-banner-background-color-1);
}

.banner-color {
    background-color: var(--skin-banner-background-color-1);
}

.header-banner {
    background-color: var(--skin-banner-background-color-2);
    color: var(--skin-banner-text-color-1);
}

.header-banner .close-button .close {
    background-color: var(--skin-primary-color-1);
}

h1.header.page-title,
h1.header.page-title::before {
    background-color: var(--skin-heading-color-1);
    color: var(--skin-heading-color-1-invert);
}

.product-tile {
    background-color: var(--skin-background-color-1);
    border: 10px solid var(--skin-border-color-1);
}

.refinements ul li button {
    color: var(--color-grey9);
}

.custom-select {
    background-color: var(--skin-selectbox-background-color-1);
    color: var(--skin-selectbox-text-color-1);
}

a {
    color: var(--skin-link-color-1);
}

.price {
    color: var(--skin-price-1);
}

.dropdown-menu,
.dropdown-menu a,
.navbar,
.navbar .nav-item,
.navbar .nav-item a,
.navbar .nav-item.dropdown,
.navbar .nav-item.dropdown a {
    color: var(--skin-menu-color-1);

    @media (min-width: 769px) { // stylelint-disable-line
        color: var(--skin-menu-color-1-invert);
    }
}

.navbar .nav-item.dropdown,
.navbar .nav-item.dropdown .dropdown-menu.show {
    color: var(--skin-selectbox-text-color-1);
}

.navbar .nav-item.dropdown .dropdown-menu.show {
    background-color: var(--skin-selectbox-background-color-1);

    a,
    .nav-item {
        color: var(--skin-selectbox-text-color-1);
    }
}

.user {
    a {
        color: var(--color-grey9);
    }
}


.nav-item .nav-link:hover,
.nav-item .nav-link:focus,
.nav-item.show .nav-link {
    background-color: transparent;
    color: inherit !important;
}


.btn-primary {
    background-color: #{var(--skin-primary-color-1)};
    border-color: #{var(--skin-primary-color-1)};
    color: var(--skin-primary-color-invert-1);
}

.btn-primary:hover {
    color: #{var(--skin-primary-color-1)};
    border-color: #{var(--skin-primary-color-1)};
    background-color: var(--skin-primary-color-invert-1);
}

.btn-primary.disabled,
.btn-primary:disabled {
    opacity: 1;
    background-color: #C8C8C8;
    color: #A4A4A4;

    &:before {
        display: none;
    }

    &:hover,
    &:active,
    &:focus {
        background-color: #C8C8C8;
        color: #A4A4A4;
    }
}

.btn-outline-primary {
    color: var(--skin-primary-color-1);
    border-color: var(--skin-primary-color-1);

    &.primary-color {
            color: var( --skin-link-color-hover-1);
            border-color: var( --skin-link-color-hover-1);
            text-transform: uppercase;
    }
}

.btn-outline-primary:hover {
    color: var(--skin-primary-color-invert-1);
    background-color: var(--skin-primary-color-1);
    border-color: var(--skin-primary-color-1);
}

.btn-outline-secondary {
    color: var(--skin-link-color-hover-1);
    border-color: var(--skin-link-color-hover-1);
}

.btn-outline-secondary:hover {
    color: #fff;
    background-color: var(--skin-primary-color-invert-1);
    border-color: var(--skin-primary-color-invert-1);
}

.btn-print {
    background-color: #F7F3EF;
    color: #536552;
}

.btn-print:hover {
    background-color: #FAF8F5;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: var(--skin-header-font), sans-serif;
}

header ~ #maincontent .container a:not(.btn-primary, .btn-outline-primary, .btn-secondary, .btn-print, .btn-white, .link-primary) {
    color: var(--skin-primary-color-1);
}

.link-primary {
    color: var(--skin-link-color-hover-1);
}

.link-primary:hover {
    color: var(--skin-primary-color-invert-1);
}